import React from "react";
import Pizza from "../components/menu/Pizza";
import GrabNGo from "../components/menu/GrabNGo";
import Rolls from "../components/menu/Rolls";
import Calzones from "../components/menu/Calzones";
import Appetizers from "../components/menu/Appetizers";
import SoupAndSalads from "../components/menu/SoupAndSalads";
import Pasta from "../components/menu/Pasta";
import Entrees from "../components/menu/Entrees";
import Heros from "../components/menu/Heros";
import Sides from "../components/menu/Sides";
import Desserts from "../components/menu/Desserts";
import Beverages from "../components/menu/Beverages";
import { ArrowUpRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();
  const handleCateringRequest = () => {
    navigate("/contact#form");
  };
  return (
    <>
      <div className="2xl:container flex flex-col gap-4 w-full md:w-1/2 mx-auto py-24 px-4">
        <Pizza />
        <GrabNGo />
        <Rolls />
        <Calzones />
        <Appetizers />
        <SoupAndSalads />
        <Pasta />
        <Entrees />
        <Heros />
        <Sides />
        <Desserts />
        <Beverages />
        <div className="flex font-typewriter mx-auto text-lg border-y w-full items-center justify-center py-4">
          <span> Catering available upon request</span>
          <ArrowUpRight
            onClick={handleCateringRequest}
            className="ml-2 cursor-pointer hover:text-brand-red"
            size={24}
          />
        </div>
        <div className="text-center text-xs font-typewriter">
          Prices are subject to change without notice
        </div>
      </div>
    </>
  );
};

export default Menu;

import { createContext, useState } from "react";

export const MenuContext = createContext();

export const MenuContextProvider = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const value = { isMenuOpen, setIsMenuOpen };
  const { Provider } = MenuContext;

  return <Provider value={value}>{props.children}</Provider>;
};

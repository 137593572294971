import React from "react";
import menu from "../../menu.json";

const GrabNGo = () => {
  return (
    <div>
      <h2 className="flex font-rig text-brand-red text-4xl justify-center mb-4">
        Grab-n-Go
      </h2>
      <div>
        <ul>
          {menu.menu["grab-n-go"].map((el) => {
            return (
              <li className="mb-4" key={el.name}>
                <div className="font-typewriter font-bold flex justify-between">
                  <span>{el.name}</span>
                  <span>
                    <sup>$</sup>
                    {el.price}
                  </span>
                </div>
                <div className="font-typewriter-cond">
                  {el.description ? el.description : null}
                </div>
                <div className="text-brand-red ">
                  {el["add_on"]
                    ? el["add_on"].map((e) => {
                        return (
                          <div
                            key={e.name}
                            className="flex items-start justify-between font-typewriter font-medium"
                          >
                            <span className="flex-1">– {e.name}</span>
                            <span className="font-bold">
                              <sup>+ $</sup>
                              {e.price} each
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GrabNGo;

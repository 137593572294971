import React from "react";

const VisitUs = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      {/* Image section */}
      <div className="bg-brand-red w-full lg:w-1/2 order-2 lg:order-2">
        <img
          src="/hero-images/robs-of-brooklyn-storefront.jpg"
          alt="Plate of meatballs in red sauce"
          className="h-auto w-full object-cover lg:object-center"
        />
      </div>

      <div className="bg-brand-tan py-8 px-4 lg:px-8 w-full lg:w-1/2 order-2 lg:order-1 flex flex-col items-center justify-center">
        <h2 className="flex font-rig text-brand-red text-6xl justify-center mb-4">
          Visit Us
        </h2>
        <div className="flex flex-col px-4 sm:px-0 gap-4 max-w-lg mx-auto text-center font-typewriter">
          <div>
            <a
              className="underline hover:text-brand-red cursor-pointer"
              href="https://goo.gl/maps/wdekH6DRL5X5Kxg18"
              target="_blank"
              rel="noreferre"
            >
              8774B Bay Pkwy
              <br />
              Brooklyn, NY 11214
            </a>
          </div>
          <hr className="w-8 border-2 border-brand-green mx-auto my-2" />
          <div>
            <a
              className="underline hover:text-brand-red cursor-pointer"
              href="tel:+13475875777"
            >
              (347) 587-5777
            </a>
            <br />
            <a
              className="underline hover:text-brand-red cursor-pointer"
              href="mailto:info@robsofbrooklyn.com"
            >
              info@robsofbrooklyn.com
            </a>
          </div>
          <hr className="w-8 border-2 border-brand-green mx-auto my-2" />
          <div>
            <span>Monday – Sunday</span>
            <br />
            11 am – 10 pm
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitUs;

import * as React from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function Gallery() {
  const [index, setIndex] = React.useState(-1);

  const slides = [
    {
      width: 1030,
      height: 1030,
      src: "/hero-images/spicy-vodka-pepperoni-square-slice.jpg",
      alt: "Spicy Vodka Pepperoni Square Slice topped with Mike's Hot Honey",
    },
    {
      width: 754,
      height: 1067,
      src: "/hero-images/calzone.jpg",
      alt: "Calzone with a side of marinara sauce",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/spaghetti-pasta-pomodoro.jpg",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/rigatoni-pasta-bolognese.jpg",
      alt: "Rigatoni Pasta Bolognese",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/buffalo-chicken-pizza-slice.jpg",
      alt: "Buffalo Chicken Pizza Slice",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/arancini.jpg",
      alt: "Arancini (Rice Balls w/ Meat) with marinara sauce and topped with grated Parmigiano-Reggiano cheese",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/meatballs-in-marinara.jpg",
      alt: "Meatballs in marinara sauce",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/baked-ziti.jpg",
      alt: "Baked Ziti",
    },
    {
      width: 800,
      height: 800,
      src: "/hero-images/spaghetti-in-garlic-and-oil.jpg",
      alt: "Spaghetti in garlic and oil",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/burrata-caprese-salad.jpg",
      alt: "Tomatoes, burrata cheese, fresh basil, topped with extra virgin olive oil and finished with balsamic glaze",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/meatball-parmigiana.jpg",
      alt: "Meatballs, mozzarella cheese, marinara sauce, grated Parmigiano-Reggiano cheese, and basil on a toasted Italian hero      ",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/garlic-knots.jpg",
      alt: "Garlic knots with side of marinara sauce",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/chicken-parmigiana-hero.jpg",
      alt: "Chicken parmigiana on a toasted hero",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/grilled-calamari-salad.jpg",
      alt: "Grilled calamari salad",
    },
    {
      width: 1016,
      height: 1015,
      src: "/hero-images/caesar-salad.jpg",
      alt: "Caesar Salad",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/margherita-pizza-slice.jpg",
      alt: "Margherita pizza slice",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/sausage-and-pepperoni-stromboli.jpg",
      alt: "Sausage and pepperoni stromboli with side of marina sauce",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/fried-calamari.jpg",
      alt: "Fried calamari",
    },
    {
      width: 900,
      height: 900,
      src: "/hero-images/rigatoni-in-vodka-sauce.jpg",
      alt: "Rigatoni pasta in vodka sauce",
    },
    {
      width: 1400,
      height: 934,
      src: "/hero-images/mussels-in-white-sauce.jpg",
      alt: "Mussels with lemon white wine sauce",
    },
    {
      width: 1000,
      height: 1067,
      src: "/hero-images/rainbow-cookies.jpg",
      alt: "Tower of rainbow cookies",
    },
    {
      width: 800,
      height: 800,
      src: "/hero-images/cannoli.jpg",
      alt: "Cannoli topped with shaved chocolate",
    },
    {
      width: 960,
      height: 1200,
      src: "/hero-images/robs-of-brooklyn-storefront.jpg",
      alt: "Rob's of Brooklyn pizzeria & restaurant storefront",
    },
    {
      width: 1400,
      height: 933,
      src: "/hero-images/robs-of-brooklyn-interior-1.jpg",
      alt: "Dining area of Rob's of Brooklyn, featuring The Last Supper painting of Biggie Smalls, Reptar, Kobe Bryant, Sonic, chef Rob Markhasin, Pac, Pop Smoke, Super Mario, and Tupac Shakur",
    },
    {
      width: 1000,
      height: 750,
      src: "/hero-images/robs-of-brooklyn-interior-2.jpg",
      alt: "Painting of John Travolta from Saturday Night Fever, eating slices of pizza in front of Rob's of Brooklyn pizzeria & restaurant",
    },
  ];

  return (
    <section className="pt-20 sm:pt-24 px-2 sm:px-4 pb-16">
      <PhotoAlbum
        layout="columns"
        spacing={8}
        columns={() => {
          if (window.innerWidth > 1024) {
            return 3;
          } else {
            return 2;
          }
        }}
        photos={slides}
        // targetRowHeight={500}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </section>
  );
}

import Hero from "../components/home/Hero";
import About from "../components/home/About";
import Order from "../components/home/Order";
import FeaturedIn from "../components/home/FeaturedIn";
import Merch from "../components/home/Merch";
import VisitUs from "../components/home/VisitUs";

const Home = () => {
  return (
    <main className="2xl:container">
      <Hero />
      <FeaturedIn />
      <About />
      <VisitUs />
      <Order />
      <Merch />
    </main>
  );
};

export default Home;

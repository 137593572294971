import React, { useState, useEffect } from "react";
import { ArrowLeft, ArrowRight } from "phosphor-react";

const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplayReset, setAutoplayReset] = useState(0);

  const handlePrev = () => {
    setAutoplayReset((prev) => prev + 1); // Reset the autoplay interval
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setAutoplayReset((prev) => prev + 1); // Reset the autoplay interval
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleIndicatorClick = (index) => {
    setAutoplayReset((prev) => prev + 1); // Reset the autoplay interval
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [autoplayReset]);

  return (
    <div className="relative aspect-auto h-[calc(90vh-64px)] sm:h-[calc(100vh-80px)] ">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${
            index === activeIndex ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            src={image.src}
            alt={image.alt}
            className="h-full w-full object-cover"
            loading="lazy"
          />
        </div>
      ))}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        {images.map((_, index) => (
          <span
            key={index}
            onClick={() => handleIndicatorClick(index)}
            className={`inline-block h-2 w-2 mx-1 rounded-full ${
              index === activeIndex
                ? "bg-brand-red cursor-pointer"
                : "bg-gray-200  cursor-pointer"
            }`}
          />
        ))}
      </div>
      <button
        onClick={handlePrev}
        className="absolute backdrop-blur-sm rounded-full border-2 border-white p-2 left-4 top-1/2 transform -translate-y-1/2 text-white"
      >
        <ArrowLeft size={20} />
      </button>
      <button
        onClick={handleNext}
        className="absolute backdrop-blur-sm rounded-full border-2 border-white p-2 right-4 top-1/2 transform -translate-y-1/2 text-white"
      >
        <ArrowRight size={20} />
      </button>
    </div>
  );
};

export default Carousel;

import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./views/Home";
import Menu from "./views/Menu";
import Contact from "./views/Contact";
import Jobs from "./views/Jobs";
import Order from "./views/Order";
import Gallery from "./views/Gallery";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { MenuContextProvider } from "./MenuContext";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <MenuContextProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/order" element={<Order />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="*" element={<Home />} /> */}
      </Routes>
      <Footer />
    </MenuContextProvider>
  );
}

export default App;

import React from "react";
import BayNews from "../../assets/brand-logos/BayNews.png";
import YouTube from "../../assets/brand-logos/youtube.svg";
import OneBite from "../../assets/brand-logos/one-bite.svg";

const FeaturedIn = () => {
  return (
    <div className="flex flex-col bg-brand-tan py-12">
      <h2 className="flex font-typewriter text-md justify-center mb-4">
        Featured In
      </h2>
      <div className="flex items-center text-justify px-4 sm:px-0 gap-8 max-w-2xl mx-auto text-xl font-typewriter">
        <a href="https://m.youtube.com/watch?v=3t0rNXz3A2k&pp=ygUScm9iJ3Mgb2YgYnJvb2tseW4g" target="_blank" rel="noreferrer">
          <img src={OneBite} alt="Uber Eats Logo" className="h-10" />
        </a>
        <a
          href="/BayNews.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={BayNews} alt="Bay News Logo" className="h-7" />
        </a>
        <a href="https://youtu.be/Z1Y9MgaDE40" target="_blank" rel="noreferrer">
          <img src={YouTube} alt="YouTube Logo" className="h-7" />
        </a>
      </div>
    </div>
  );
};

export default FeaturedIn;

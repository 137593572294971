import React from "react";
import { Link } from "react-router-dom";

const navigationItems = [
  { name: "Home", url: "/" },
  { name: "Menu", url: "/menu" },
  { name: "Gallery", url: "/gallery" },
  // { name: "Press", url: "/press" },
  { name: "Order", url: "/order" },
  { name: "Jobs", url: "/jobs" },
  { name: "Merch", url: "https://shop.robsofbrooklyn.com", external: true },
  { name: "Contact", url: "/contact#form" },
];

const FooterNavigation = () => {
  return (
      <ul className="flex flex-wrap justify-center 2xl:container 2xl:justify-start gap-2 sm:gap-4">
        {navigationItems.map((el) => {
          return (
            <li key={el.name}>
              {el.external ? (
                <a href={el.url}>{el.name}</a>
              ) : (
                <Link to={el.url}>{el.name}</Link>
              )}
            </li>
          );
        })}
      </ul>
  );
};

export default FooterNavigation;

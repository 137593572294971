import React from "react";
import CoverImage from "../../assets/images/food/DSC08153.webp";
import { ArrowDown } from "phosphor-react";

const Hero = () => {
  const scrollToOrderSection = () => {
    const orderSection = document.getElementById("order");
    if (orderSection) {
      orderSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="py-8 relative h-screen overflow-hidden">
      <img
        className="absolute top-0 left-0 h-full w-full object-cover"
        src={CoverImage}
        alt="Plate of meatballs in red sauce"
      />
      <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>

      <div className=" absolute top-0 left-0 h-full w-full flex items-center justify-center p-2">
        <div className="text-center text-white">
          <h1 className="text-6xl sm:text-8xl font-rig mb-4">Order</h1>
          <p className="text-lg md:text-xl lg:text-2xl font-typewriter-cond">
            All your favorite dishes, on your favorite food delivery apps.
            <br />
            Order now!
          </p>
          <ArrowDown
            onClick={scrollToOrderSection}
            className="cursor-pointer mx-auto my-8 text-center text-6xl animate-bounce"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;

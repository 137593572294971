import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="flex flex-col py-12">
      <h2 className="flex font-rig text-brand-red text-6xl justify-center mb-4">
        About
      </h2>
      <div className="flex flex-col text-justify px-4 sm:px-0 gap-4 max-w-2xl mx-auto text-xl font-typewriter">
        <span className="text-center text-sm font-bold">Est. 2022</span>
        <p>
          Step into the world of Rob's of Brooklyn – where pizza is not just a
          dish, but a portal to the past, a vibrant slice of history made
          deliciously present. Tucked within the heart of Bensonhurst, Brooklyn,
          a legendary enclave of NY pizza culture, we proudly stand as the
          newcomers embracing an age-old pizza tradition.
        </p>
        <p>
          Traditionalists and trailblazers alike, you're all invited to our
          culinary haven. The tapestry of our menu threads together a narrative
          of flavors deeply influenced by Brooklyn's soul, a tale envisioned by
          our owner, Rob.
        </p>
        <p>
          Indulge in iconic NY-style pizzas and other delectable
          Italian-American dishes that delight your tastebuds.
        </p>
        <p>
          Trying to compare Brooklyn pizza to the rest of the world? Well, let's
          just say, <em>"Fuhgeddaboudit."</em>
        </p>
        <Link
          className="block max-w-[180px] mx-auto text-center text-white bg-brand-red py-2 px-6 font-typewriter rounded-md font-bold text-xl hover:bg-brand-red-dark transition-colors duration-200"
          to="/menu"
        >
          View Menu
        </Link>
      </div>
    </div>
  );
};

export default About;

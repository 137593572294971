import React, { useEffect, useState } from "react";
import Map from "../components/contact/Map";
import ContactForm from "../components/contact/ContactForm";

const Contact = () => {
  const [currentHash, setCurrentHash] = useState(null);

  useEffect(() => {
    // console.log(currentHash);
    setCurrentHash(window.location.hash.slice(1));
  }, [window.location.hash]);

  useEffect(() => {
    if (currentHash) {
      document
        .getElementById(currentHash)
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [currentHash]);

  return (
    <div
      id="location-and-hours"
      className="flex flex-col gap-4 w-full md:w-1/2 mx-auto py-24 px-4"
    >
      <div>
        <Map />
      </div>
      <div className="flex flex-col gap-2 text-center font-typewriter justify-center">
        <h2 className="font-rig text-brand-red text-3xl mt-6">
          Location & Hours
        </h2>
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href="https://goo.gl/maps/wdekH6DRL5X5Kxg18"
            target="_blank"
            rel="noreferre"
          >
            8774B Bay Pkwy
            <br />
            Brooklyn, NY 11214
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto my-2" />
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href="tel:+13475875777"
          >
            (347) 587-5777
          </a>
          <br />
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href="mailto:info@robsofbrooklyn.com"
          >
            info@robsofbrooklyn.com
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto my-2" />
        <div>
          <span>Monday – Sunday</span>
          <br />
          11 am – 10 pm
        </div>
        <hr className="my-4" id="form" />
      </div>
      <div className="">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;

import React from "react";

const Map = () => {
  return (
    <div>
      <iframe
        className="mx-auto min-h-[300]"
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.4259872720386!2d-74.00029202391717!3d40.598424071410996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2459bbeb31ae9%3A0x44d30cd1cf9dc8b4!2sRob&#39;s%20of%20Brooklyn!5e0!3m2!1sen!2sus!4v1684598621520!5m2!1sen!2sus"
        width="100%"
        height="400"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;

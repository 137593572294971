import React from "react";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <div className="flex gap-2 text-white justify-center text-center py-6">
      <Link
        className="block min-w-[180px] bg-brand-green py-2 px-6 font-typewriter rounded-md font-bold text-xl hover:bg-brand-green-dark transition-colors duration-200"
        to="/menu"
      >
        View Menu
      </Link>
      <Link
        className="block min-w-[180px] bg-brand-red py-2 px-6 font-typewriter rounded-md font-bold text-xl hover:bg-brand-red-dark transition-colors duration-200"
        to="/order"
      >
        Order Online
      </Link>
    </div>
  );
};

export default CTA;

import React from "react";
import logo from "../assets/images/robs-of-brooklyn-logo.svg";

const Logo = () => {
  return (
    <div>
      <img className="h-12 sm:h-16" src={logo} alt="Rob's of Brooklyn Logo" />
    </div>
  );
};

export default Logo;

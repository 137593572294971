import React from "react";
import { InstagramLogo, FacebookLogo, TiktokLogo } from "phosphor-react";

const socialMediaAccounts = [
  {
    name: "Instagram",
    url: "https://instagram.com/robsofbrooklyn",
    icon: <InstagramLogo />,
  },
  {
    name: "Facebook",
    url: "https://facebook.com/robsofbrooklyn",
    icon: <FacebookLogo />,
  },
  {
    name: "TikTok",
    url: "https://tiktok.com/@robsofbrooklyn",
    icon: <TiktokLogo />,
  },
];

const SocialMediaAccounts = ({ size }) => {
  return (
    <div className="flex gap-3">
      {socialMediaAccounts.map((el) => {
        return (
          <a target="_blank" className="hover:scale-110 transition-all duration-200" rel="noreferrer" key={el.name} href={el.url}>
            {{ ...el.icon, props: { size } }}
          </a>
        );
      })}
    </div>
  );
};

export default SocialMediaAccounts;

import SocialMediaAccounts from "./SocialMediaAccounts";
import FooterNavigation from "./FooterNavigation";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="flex flex-col">
      <div className="md:flex 2xl:px-0 px-8 py-2 bg-brand-green text-white justify-start font-typewriter-cond">
        <FooterNavigation />
      </div>
      <div className="text-white bg-brand-red">
        <div className="2xl:container 2xl:px-0 flex flex-col-reverse gap-4 sm:flex-row px-8 py-2 font-typewriter-cond items-center sm:justify-between text-xs sm:text-sm">
          <div className=" flex-1">
            Copyright © {currentYear} Rob's of Brooklyn LLC. All rights
            reserved.
          </div>
          <div>
            <SocialMediaAccounts size={32} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import Doordash from "../assets/brand-logos/doordash.svg";
import Grubhub from "../assets/brand-logos/grubhub.svg";
import Seamless from "../assets/brand-logos/seamless.svg";
import Slice from "../assets/brand-logos/slice.svg";
import UberEats from "../assets/brand-logos/ubereats.svg";
import Postmates from "../assets/brand-logos/postmates.svg";
import Hero from "../components/order/Hero";
import { PhoneCall } from "phosphor-react";

const onlineOrderingPlatforms = [
  {
    name: "Doordash",
    url: "https://www.doordash.com/store/rob's-of-brooklyn-brooklyn-24011033/",
    logo: Doordash,
  },
  {
    name: "Grubhub",
    url: "https://www.grubhub.com/restaurant/robs-of-brooklyn-8774b-bay-parkway-brooklyn/4381368",
    logo: Grubhub,
  },
  {
    name: "Postmates",
    url: "https://postmates.com/store/robs-of-brooklyn/wWt6q99WRdqKNbD-j5rRwg",
    logo: Postmates,
  },
  {
    name: "Seamless",
    url: "https://www.seamless.com/menu/robs-of-brooklyn-8774b-bay-parkway-brooklyn/4381368",
    logo: Seamless,
  },
  {
    name: "Slice",
    url: "https://slicelife.com/restaurants/ny/brooklyn/11214/rob-s-of-brooklyn/menu",
    logo: Slice,
  },
  {
    name: "Uber Eats",
    url: "https://www.ubereats.com/store/robs-of-brooklyn/wWt6q99WRdqKNbD-j5rRwg",
    logo: UberEats,
  },
];

const Order = () => {
  return (
    <div className="flex 2xl:container flex-col gap-4 w-full mx-auto py-12">
      <Hero />
      <div id="order" className="flex flex-col pt-20 items-center">
        <h2 className="font-rig text-3xl">Call to Order</h2>
        {/* <Phone weight="fill" className="h-8 mr-2" /> */}
        <a href="tel:+13475875777">
          <span className="flex items-center my-6 px-4 py-2 bg-brand-red text-white rounded-md text-3xl font-typewriter font-bold hover:bg-brand-red-dark">
            <PhoneCall weight="fill" className="mr-3" />
            (347) 587-5777
          </span>
        </a>
        <div className="my-4">
          <hr className="border-2 border-brand-green" />
          <span className="font-typewriter text-brand-green font-bold">or</span>
          <hr className="border-2 border-brand-green" />
        </div>
      </div>
      <div className="flex max-w-2xl mx-auto justify-center flex-col sm:flex-row sm:flex-wrap  gap-4 p-2">
        {onlineOrderingPlatforms.map((e) => {
          return (
            <a
              href={e.url}
              target="_blank"
              rel="noreferrer"
              key={e.name}
              className="flex rounded-md justify-center items-center p-8 border-2 hover:bg-gray-50 cursor-pointer w-full sm:w-1/3 h-12 box-shadow-md"
            >
              <img className="h-8" src={e.logo} alt={`${e.name}logo`} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Order;

import React from "react";
import menu from "../../menu.json";

const Heros = () => {
  return (
    <div>
      <h2 className="flex font-rig text-brand-red text-4xl justify-center mb-4">
        Heros
      </h2>
      <div>
        <ul>
          {menu.menu["heros"].map((el) => {
            return (
              <li className="mb-4" key={el.name}>
                <div className="font-typewriter font-bold flex justify-between">
                  <span>{el.name}</span>
                  <span>
                    {typeof el.price === "object" ? (
                      <>
                        {Object.entries(el.price).map(([key, value], index) => (
                          <span key={index}>
                            <sup>{key}:</sup> <sup>$</sup>
                            {value}
                            {index !== Object.entries(el.price).length - 1 && (
                              <span> / </span>
                            )}
                          </span>
                        ))}
                      </>
                    ) : (
                      <>
                        <sup>$</sup>
                        <>{el.price}</>
                      </>
                    )}
                  </span>
                </div>
                <div className="font-typewriter-cond">
                  {el.description ? el.description : null}
                </div>
                <div className="text-brand-red ">
                  {el["add_on"]
                    ? el["add_on"].map((e) => {
                        return (
                          <div
                            key={e.name}
                            className="flex items-start justify-between font-typewriter font-medium"
                          >
                            <span className="flex-1">– {e.name}</span>
                            <span className="font-bold">
                              <sup>+ $</sup>
                              {e.price}
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Heros;
